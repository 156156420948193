import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@core';
import { ArrowIcon, Flag, ImageCore, LinkWrapper, Text } from '@components';

import redCross from '@images/red-cross.svg';
import checkMark from '@images/checkMark.svg';

const HeaderRow = ({ featureNames }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row minHeight={48} backgroundColor="tableBackground" alignItems="center">
      <Grid.Col display="flex" width={308} pl={40} justifyContent="flex-start">
        <Text.Body3>{t('servers:serverListSection.location')}</Text.Body3>
      </Grid.Col>
      {Object.values(featureNames).map((value) => (
        <Grid.Col display="flex" width={120} justifyContent="center" key={value}>
          <Text.Body3 textAlign="center">{value}</Text.Body3>
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

const LocationColumn = ({ location }) => (
  <Grid.Col display="flex" width={308} pl={40} justifyContent="flex-start">
    <Box display="flex" alignItems="center">
      <Box display="flex" flexShrink={0}>
        <Flag iso={location.iso} width={24} />
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" ml={16}>
        <Text.Body3Strong mr={8}>{location.countryName}</Text.Body3Strong>
        {location.cityName && (
          <Text.Body3Strong color="darkBlue_60">{location.cityName}</Text.Body3Strong>
        )}
      </Box>
    </Box>
  </Grid.Col>
);

const FeatureValueColumn = ({ value }) => (
  <Grid.Col display="flex" width={120} justifyContent="center">
    {value ? (
      <ImageCore src={checkMark} alt="check mark" />
    ) : (
      <ImageCore src={redCross} alt="red cross" />
    )}
  </Grid.Col>
);

const LinkColumn = ({ link }) => (
  <Grid.Col width={48} height={40}>
    {link && (
      <LinkWrapper link={link}>
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" alignItems="center" width={7} height={12}>
            <ArrowIcon innerColor="#D4D5DB" outerColor="#7F8193" />
          </Box>
        </Box>
      </LinkWrapper>
    )}
  </Grid.Col>
);

const ServerListTableDesktop = ({ data, featureNames }) => (
  <Box overflowX="auto">
    <Grid.Container width="max-content">
      <HeaderRow featureNames={featureNames} />
      {data.map((item, index) => (
        <Grid.Row
          minHeight={48}
          backgroundColor={index % 2 !== 0 ? 'tableBackground' : 'white'}
          alignItems="center"
          key={index}
        >
          <LinkWrapper link={item.link}>
            <LocationColumn location={item.location} />
          </LinkWrapper>
          {Object.keys(featureNames).map((key) => (
            <FeatureValueColumn value={item.features[key]} key={key} />
          ))}
          <LinkColumn link={item.link} />
        </Grid.Row>
      ))}
    </Grid.Container>
  </Box>
);

export default ServerListTableDesktop;
