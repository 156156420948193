import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionGroup, Box, Button } from '@core';
import { Animation, ArrowIcon, Flag, ImageCore, LinkWrapper, Text } from '@components';

import redCross from '@images/red-cross.svg';
import checkMark from '@images/checkMark.svg';

const ArrowWrapper = styled.div`
  transform: rotate(90deg);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
`;

const Header = ({ isOpen, location, backgroundColor }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    minHeight={48}
    pl={24}
    pr={16}
    backgroundColor={backgroundColor}
  >
    <Box display="flex" alignItems="center">
      <Box display="flex" flexShrink={0}>
        <Flag iso={location.iso} width={24} />
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" ml={16}>
        <Text.Body3Strong mr={8} textAlign="left">
          {location.countryName}
        </Text.Body3Strong>
        {location.cityName && (
          <Text.Body3Strong color="darkBlue_60" textAlign="left">
            {location.cityName}
          </Text.Body3Strong>
        )}
      </Box>
    </Box>
    <Box pl={24}>
      <Animation.Rotate rotate={isOpen}>
        <Box width={24} display="flex" alignItems="center" justifyContent="center">
          <ArrowWrapper>
            <ArrowIcon innerColor="#D4D5DB" outerColor="#7F8193" />
          </ArrowWrapper>
        </Box>
      </Animation.Rotate>
    </Box>
  </Box>
);

const Content = ({ item, backgroundColor, featureNames }) => {
  const { t } = useTranslation();

  return (
    <Box width="100%" pt={12} pl={24} pr={16} backgroundColor={backgroundColor}>
      {Object.keys(featureNames).map((key) => (
        <Box pb={16} width="100%" display="flex" justifyContent="space-between" key={key}>
          <Text.Body4>{featureNames[key]}</Text.Body4>
          <Box display="flex" justifyContent="center" alignItems="center" width={24}>
            {item.features[key] ? (
              <ImageCore src={checkMark} alt="check mark" />
            ) : (
              <ImageCore src={redCross} alt="red cross" />
            )}
          </Box>
        </Box>
      ))}
      {item.link && (
        <Box pb={16} width="100%" display="flex" justifyContent="space-between">
          <LinkWrapper link={item.link}>
            <Button type="button" variant="text">
              {t('common:links.learnMore')}
            </Button>
          </LinkWrapper>
        </Box>
      )}
    </Box>
  );
};

const ServerListTableMobile = ({ data, featureNames }) => (
  <AccordionGroup
    accordion={Accordion}
    data={data.map((item, index) => ({
      header: ({ isOpen }) => (
        <Header
          isOpen={isOpen}
          location={item.location}
          backgroundColor={index % 2 === 0 ? 'tableBackground' : 'white'}
        />
      ),
      content: () => (
        <Content
          item={{ features: item.features, link: item.link }}
          featureNames={featureNames}
          backgroundColor={index % 2 === 0 ? 'tableBackground' : 'white'}
        />
      ),
    }))}
    initiallyOpenedItem={null}
    allowZeroExpanded
  />
);

export default ServerListTableMobile;
