import React from 'react';

import { Show } from '@core';

import { ServerListTableDesktop, ServerListTableMobile } from './layouts';

const ServerListTable = ({ data, featureNames }) => (
  <React.Fragment>
    <Show.TabletLarge>
      <ServerListTableMobile data={data} featureNames={featureNames} />
    </Show.TabletLarge>

    <Show.DesktopAndUp>
      <ServerListTableDesktop data={data} featureNames={featureNames} />
    </Show.DesktopAndUp>
  </React.Fragment>
);

export default ServerListTable;
